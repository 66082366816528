document.addEventListener("DOMContentLoaded", function() {

  if (window.location.pathname === '/tabelas') {
    if ($.cookie('access_imob')) {
      $('.loading').removeClass('active')
    } else {
      window.location = '/#login';
    }
  }

  $('.btn-logout').click(function() {
    $.removeCookie('access_imob', { path: '/' });
      window.location = '/';
  })
  var toggleModal = document.querySelector("#toggle-modal-access"),
    modal = document.querySelector('.modal__access'),
    body = document.querySelector('body'),
    overlayModal = document.querySelector('.overlay--modal');

  function modalAccess(element)  {
    element.addEventListener('click', function () {
      body.classList.toggle('modal-is-active');
      body.classList.remove('active-menu');
    }, false);
  }

  modalAccess(overlayModal);
  modalAccess(toggleModal);
});


PageInits = {
  getUrlQueryParams() {
    var queryString = window.location.href.split("#")[1];
    return queryString;
  },

  checkRequestLoginUserToFiles() {
    $body = document.querySelector('body');
    var currentParams = PageInits.getUrlQueryParams();

    if (currentParams === 'login') {
      $body.classList.add('modal-is-active');
    }
  }
}
PageInits.checkRequestLoginUserToFiles();

