$('.js-change-to-signup').click(function (){
  $(".form-signup").toggleClass('active');
  $(".form-login").removeClass('active');
  $('.modal__access').addClass('modal__access--big')
})


$("#login").submit(function(e){
  e.preventDefault();

  var btnLogin = document.querySelector('.js-btn-login');
  var data = $(this).serializeArray().reduce(function(obj, item) {
    obj[item.name] = item.value;
    return obj;
  }, {});


  btnLogin.disabled = true;
  btnLogin.innerHTML= 'carregando...';

  var customHeaders = {
    'Accept':       'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  };

  var myInit = {
    method: 'POST',
    headers: customHeaders,
    mode: 'cors',
    body: JSON.stringify({
      password: data.password,
      email: data.email
    })
  };

  fetch('/tabelas/actions/login', myInit)
    .then(function(response) {
      if(response.ok) {
        $.cookie('access_imob', data.email, { expires: 7 });
        window.location = '/tabelas';
        btnLogin.disabled = false;
        btnLogin.innerHTML = 'Acessar Tabelas';
      } else {
        alert('Login ou senha invalidos');

        btnLogin.disabled = false;
        btnLogin.innerHTML = 'Acessar tabelas';
      }
    }).then(function(response) {
      console.log(response);
    })
});
